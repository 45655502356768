const copyToClipboard = str => {
	const el = document.createElement('textarea');
	el.value = str;
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
};

export default {
	init: () => {

	},
	loaded: () => {
		document.querySelectorAll('a.copypaste_url, .copypaste_url a').forEach(element => {
			element.classList.add('relative', 'inline-flex', 'items-center', 'gap-1', 'translate-y-[0.0625rem]');
			let copyPasteLabel = element.getAttribute('data-label') ?? element.parentElement.dataset.label ?? 'Copied!';
			let label = element.innerHTML;
			element.addEventListener('click', function(e){
				// element.innerHTML += `<span class="msg absolute px-1 block top-0 left-0 whitespace-nowrap text-center bg-white shadow rounded">${copyPasteLabel}</span>`;
				element.innerHTML = copyPasteLabel;
				copyToClipboard(element.href);

				setTimeout(() => {
					element.innerHTML = label;
				}, 500);
				e.preventDefault();
			});
		});
		document.querySelectorAll('.has-2-xl-font-size').forEach(element => {
			element.classList.add(`text-2xl`);
		});

		document.querySelectorAll('a[href="#"]').forEach(link => {
			link.addEventListener('click', function(e){
				e.preventDefault();
			});
		});

// Jerryhacked method until WP-Activitypub 2.0.0.
		document.querySelectorAll('p.wp-has-aspect-ratio').forEach(video => {
			video.classList.add('wp-block-embed', 'is-type-video');
			video.innerHTML = `<figure class="wp-block-embed__wrapper">${video.innerHTML}</figure>`;
			video.closest('.post').classList.add('has_embed');
		});
// end Jerryhacked

		document.querySelectorAll('.wp-block-embed.wp-has-aspect-ratio').forEach(video => {
			let m = video.className.match(/wp\-embed\-aspect\-([0-9]+)\-([0-9]+)/);
			let iframe = video.querySelector('iframe');

			if (iframe !== null) {
				iframe.style.aspectRatio = `${m[1]}/${m[2]}`;
				iframe.style.height = `auto`;
			}
		});

		document.querySelectorAll('.post.category-microblog').forEach(element => {
			let block = element.querySelector('.wp-block-image,.wp-block-embed,.wp-block-gallery');
			if (block !== null && !block.classList.contains('plain')) {
				element.classList.add('offset');
				block.classList.add('offset-element');
				element.querySelector('header').prepend(block);
			}
		});

		document.querySelectorAll('.wp-block-gallery').forEach(block => {
			// let id = `gallery_${i}`;
			if (block.childElementCount % 2 === 0) {
				block.classList.add('gallery-has-even');
			}
			else {
				block.classList.add('gallery-has-odd');
			}
			block.classList.add('gallery-has-' + block.childElementCount);
		});


		document.querySelectorAll('[data-action="open"]').forEach(element => {
			element.addEventListener('click', function(e){
				let target = this.getAttribute('data-target');
				document.querySelectorAll(target).forEach(el1 => {
					el1.classList.remove('!hidden');
				});
				if (this.getAttribute('aria-label') === 'Open searchform') {
					document.querySelector(target).querySelector('#s').focus();
				}
				e.preventDefault();
			});
		});

		document.querySelectorAll('[data-action="close"]').forEach(element => {
			element.addEventListener('click', function(e){
				let target = this.getAttribute('data-target');
				document.querySelectorAll(target).forEach(el1 => {
					el1.classList.add('!hidden');
				});
				// e.preventDefault();
			});
		});
	}
}
