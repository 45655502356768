export default function encode_utf8( s ){
	return unescape( encodeURIComponent( s ) );
}
export function stripHTML(html){
	 let doc = new DOMParser().parseFromString(html, 'text/html');
	 return doc.body.textContent || "";
}

export function htagClasses(){
	document.querySelectorAll('h1, h2, h3, h4, h5, h6').forEach(element => {
		let classes = element.className

		if ( classes === undefined || classes === null || ( classes !== undefined && classes !== null && classes.match(/^(.+ )?h[0-6]/) === null ) ) {
			element.classList.add(element.tagName.toLowerCase());
		}
	})
}

export function isTouchDevice() {
	return (('ontouchstart' in window) ||
		 (navigator.maxTouchPoints > 0) ||
		 (navigator.msMaxTouchPoints > 0));
}
