import Breakpoints from '@giantpaper/breakpoints.js';
import { isTouchDevice } from '@scripts/misc';

export default {
	init: () => {

	},
	loaded: () => {
		document.querySelector('#main_menu').classList.remove('h-0');

		let run = () => {
			//-- REALLY BIG TOUCHSCREENS -- trigger megamenu
			if ( isTouchDevice() && breakpoint.gte('lg') ) {
				document.querySelector('.megamenu_parent').addEventListener('click', function(){
					this.classList.toggle('hover')
				})
			}
			//-- END trigger megamenu
		}
		let breakpoint = new Breakpoints
		run()
		window.addEventListener('resize', () => run)

		document.querySelector('#searchform #s').addEventListener('keyup', function(){
			let form = this.closest('form');
			if (this.value === '') {
				form.querySelector('a[type="close"]').classList.remove('!hidden');
				form.querySelector('button[type="submit"]').classList.add('!hidden');
			}
			else {
				form.querySelector('a[type="close"]').classList.add('!hidden');
				form.querySelector('button[type="submit"]').classList.remove('!hidden');
			}
		});
		let menuBtn = document.querySelector('#menubtn')
		menuBtn.addEventListener('mouseup', function(){
			this.classList.toggle('clicked')
			document.querySelector('#main_menu').classList.toggle('open')
			document.querySelector('body').classList.toggle('menu_open')
		})

		document.querySelector('#searchform').addEventListener('submit', function(e){
			if (this.querySelector('#s').value === '') {
				e.preventDefault();
			}
		});
	},
}
