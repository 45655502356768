const iconAndText = (selector, labelSelector, icon) => {
	let block = document.querySelector(selector);
	let last = selector.split(' ').pop();
	let uid = last.replace(/[^a-z0-9A-Z]+/, '') + '__label';
	block.innerHTML = `<span class="fa">${icon}</span>${block.innerHTML}`;
	block.querySelector('svg').setAttribute('role', 'img');
	block.querySelector('svg').setAttribute('aria-labelledby', uid);
	block.querySelector(labelSelector).id = uid;
};

export default {
	init: () => {

	},
	loaded: () => {
		iconAndText('#bottom .info', 'p', '<svg xmlns="http://www.w3.org/2000/svg" width="18.883" height="47.208" viewBox="0 0 18.883 47.208" data-v-56b94790=""><path id="info" d="M5.508,35.934a3.934,3.934,0,1,1,3.934,3.934A3.934,3.934,0,0,1,5.508,35.934ZM1.574,48.523a2.355,2.355,0,0,1,2.36-2.36H9.442a2.355,2.355,0,0,1,2.36,2.36V74.487h4.721a2.36,2.36,0,0,1,0,4.721H2.36a2.36,2.36,0,0,1,0-4.721H7.081v-23.6H3.934A2.355,2.355,0,0,1,1.574,48.523Z" transform="translate(0 -32)" data-v-56b94790=""></path></svg>');
		iconAndText('#bottom .reading', 'strong', '<svg xmlns="http://www.w3.org/2000/svg"	 viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M512 337.2V52.4L344 77V373l168-35.8zM296 373V77L128 52.4V337.2L296 373zM523.4 2.2C542.7-.7 560 14.3 560 33.8V350.1c0 15.1-10.6 28.1-25.3 31.3l-201.3 43c-8.8 1.9-17.9 1.9-26.7 0l-201.3-43C90.6 378.3 80 365.2 80 350.1V33.8C80 14.3 97.3-.7 116.6 2.2L320 32 523.4 2.2zM38.3 23.7l10.2 2c-.3 2.7-.5 5.4-.5 8.1V74.6 342.1v66.7l265.8 54.5c2 .4 4.1 .6 6.2 .6s4.2-.2 6.2-.6L592 408.8V342.1 74.6 33.8c0-2.8-.2-5.5-.5-8.1l10.2-2C621.5 19.7 640 34.8 640 55V421.9c0 15.2-10.7 28.3-25.6 31.3L335.8 510.4c-5.2 1.1-10.5 1.6-15.8 1.6s-10.6-.5-15.8-1.6L25.6 453.2C10.7 450.2 0 437.1 0 421.9V55C0 34.8 18.5 19.7 38.3 23.7z"/></svg>');
		iconAndText('#bottom .watching', 'strong', '<svg xmlns="http://www.w3.org/2000/svg"	 viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M135 7c9.4-9.4 24.6-9.4 33.9 0l87 87L343 7c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55H432c44.2 0 80 35.8 80 80V432c0 44.2-35.8 80-80 80H80c-44.2 0-80-35.8-80-80V176c0-44.2 35.8-80 80-80H190.1L135 41c-9.4-9.4-9.4-24.6 0-33.9zM80 144c-17.7 0-32 14.3-32 32V432c0 17.7 14.3 32 32 32H432c17.7 0 32-14.3 32-32V176c0-17.7-14.3-32-32-32H80zm336 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48zM392 304a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM144 192H304c26.5 0 48 21.5 48 48V368c0 26.5-21.5 48-48 48H144c-26.5 0-48-21.5-48-48V240c0-26.5 21.5-48 48-48z"/></svg>');
		iconAndText('#bottom .playing', 'strong', '<svg xmlns="http://www.w3.org/2000/svg"	 viewBox="0 0 640 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M216 336H424c22 0 41.2 15 46.6 36.4l3.6 14.3c6.7 26.6 30.6 45.3 58 45.3c33 0 59.8-26.8 59.8-59.8v-2.8c0-3.5-.3-7.1-.9-10.6L558.4 168c-6.2-36-27.8-60.4-54.6-67.1C461.4 90.3 399.7 80 320 80s-141.5 10.3-183.7 20.9C109.4 107.6 87.8 132 81.6 168L48.9 358.8c-.6 3.5-.9 7-.9 10.6v2.8c0 33 26.8 59.8 59.8 59.8c27.4 0 51.4-18.7 58-45.3l3.6-14.3C174.8 351 194 336 216 336zm211.6 62.3L424 384H216l-3.6 14.3c-12 48-55.1 81.7-104.6 81.7C48.3 480 0 431.7 0 372.2v-2.8c0-6.3 .5-12.5 1.6-18.7L34.3 159.8c8.6-50.2 40.9-93.2 90.3-105.5C170.5 42.9 236.2 32 320 32s149.5 10.9 195.3 22.3c49.4 12.3 81.7 55.3 90.3 105.5l32.7 190.9c1.1 6.2 1.6 12.4 1.6 18.7v2.8C640 431.7 591.7 480 532.2 480c-49.5 0-92.6-33.7-104.6-81.7zM232 168v32h32c13.3 0 24 10.7 24 24s-10.7 24-24 24H232v32c0 13.3-10.7 24-24 24s-24-10.7-24-24V248H152c-13.3 0-24-10.7-24-24s10.7-24 24-24h32V168c0-13.3 10.7-24 24-24s24 10.7 24 24zm168 72a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm32-64a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>');
	},
}
